<template>
  <section class="specialism-list flex-column gap-half">
    <Typography variant="h6">Most Popular</Typography>
    <el-scrollbar>
      <div class="specialism-list__scrollbar-content grid" v-if="availableCategories.length">
        <SpecialismItem
          v-for="(category, index) in mostPopularCategories"
          :key="index"
          :isActive="isCategorySelected(category?.value)"
          :category="category"
          @click="handleSelectCategory(category?.value)"/>
      </div>
    </el-scrollbar>
  </section>
</template>
<script>
import { defineComponent } from 'vue';

import Typography from '@/core/components/ui/Typography.vue';
import SpecialismItem from '@/modules/account-setup/components/SpecialismItem.vue';

export default defineComponent({
  components: {
    SpecialismItem,
    Typography
  },

  emits: ['select-category'],

  props: ['availableCategories', 'selectedCategories'],

  data() {
    return {
      popularCategories: [1, 2, 4, 13, 8, 41],
    };
  },

  computed: {
    mostPopularCategories() {
      // Filter availableCategories based on popularCategories
      return this.availableCategories.filter((category) => {
        return this.popularCategories.includes(category.value);
      });
    },
  },

  methods: {
    handleSelectCategory(value) {
      this.$emit('select-category', value);
    },

    isCategorySelected(value) {
      return this.selectedCategories.includes(value);
    },
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.specialism-list {
  height: 100%;

  &__scrollbar-content {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(65px, 1fr));
    padding: 0.4rem 0.5rem;
    box-sizing: border-box;
  }
}

@include media-xs-max-width() {
  .specialism-list {
    &__scrollbar-content {
      gap: .7rem;
    }
  }
}

@media screen and (max-width: 509px) {
  .specialism-list {
    &__scrollbar-content {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }
}
</style>
