<template>
  <div>
    <InfoCardSlot
      class="specialism-item"
      :hasCustomHeader="true"
      actionLabel="Add Specific Expertise"
      @handle-event-click="setSpecificSpecialismModal(true)">
      <template #custom-header>
        <div class="flex-row ai-c jc-sb">
          <!-- NOTE: Create a reusable component for this molecule -->
          <div class="flex-row ai-c gap-half">
            <!-- NOTE: Create a reusable component for this icon -->
            <span class="specialism-item__category-icon flex-row ai-c jc-c">
              <inline-svg
                class="specialism-item__img"
                width="20px" height="20px"
                :src="require(`@/assets/category-icons/${category?.iconName.concat('.svg')}`)"></inline-svg>
            </span>
            <div class="passport-profile__wrapper flex-column gap-0">
              <Typography variant="h6" lineHeight="14px">{{ category?.label }}</Typography>
              <!-- <Typography variant="custom" textSize="0.75rem" textWeight="400" textColor="rgba(12, 15, 74, .5)">
                Added: 29 / 11 / 2023
              </Typography> -->
            </div>
          </div>
          <Icon
            @click="removeMainCategory(category.value)"
            class="cursor-pointer"
            iconName="trash-icon.svg"
            iconHeight="15px"
            iconWidth="15px"/>
        </div>
      </template>
      <template #custom-content>
        <div class="specialism-item__content flex-column jc-fs gap-1 h-auto w-100">
          <div class="specialism-item__wrapper flex-column w-100">
            <div class="flex-row ai-c jc-sb w-100"
              v-for="(subCategory, index) in category?.children" :key="index">
              <Typography
                variant="custom"
                textWeight="500"
                textSize="14px">
                {{ subCategory.label }}
              </Typography>
              <Icon
                @click="removeSubCategory(subCategory.value)"
                class="cursor-pointer"
                iconName="trash-icon.svg"
                iconHeight="15px"
                iconWidth="15px"/>
            </div>
          </div>
        </div>
      </template>
    </InfoCardSlot>

    <!-- MODAL -->
    <AddSpecificSpecialismModal :show="specificSpecialismModal" @on-cancel="onClose"/>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

import InfoCardSlot from '@/core/components/slots/InfoCardSlot.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import AddSpecificSpecialismModal from '@/modules/trades-passport-children/components/modals/AddSpecificSpecialismModal.vue';

export default defineComponent({
  components: {
    InfoCardSlot,
    Typography,
    Icon,
    AddSpecificSpecialismModal
  },

  emits: ['remove-subCategory', 'remove-main-category'],

  props: ['category'],

  data() {
    return {
      specificSpecialismModal: false
    };
  },

  methods: {
    removeMainCategory(mainCategoryId) {
      this.$emit('remove-main-category', mainCategoryId);
    },

    removeSubCategory(subCategoryId) {
      this.$emit('remove-subCategory', subCategoryId);
    },

    setSpecificSpecialismModal(state) {
      this.specificSpecialismModal = state;
    },

    onClose() {
      this.setSpecificSpecialismModal(false);
    }
  },
});
</script>
<style lang="scss" scoped>
.specialism-item {

  &__content {
    border-top: 1px solid #C5D2F2;
    padding-top: 1rem;
    box-sizing: border-box;
  }
  &__wrapper.grid {
    max-height: 200px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__category-icon {
    width: 40px;
    height: 40px;
    min-width: 16px;
    background: #C5D2F2;
    border-radius: 100%;
    box-sizing: border-box;
  }

  &__img {
    :deep(path) {
      fill: #264FD5;
      fill-opacity: 1;
    }
  }
}
</style>
