<template>
  <BaseModal
    :show="show"
    :showCancelBtn="false"
    :showSubmitBtn="false"
    :showDefaultCloseBtn="false"
    @cancel="cancel"
    :hasActions="false"
    >
    <div class="add-specific-specialism flex-column jc-sb">
        <div class="add-specific-specialism__container flex-column">
          <div class="add-specific-specialism__header flex-row ai-c jc-sb">
              <div class="flex-column ai-fs">
                <h2 class="add-specific-specialism__title default-text">Add Specific Specialism</h2>
              </div>
              <inline-svg class="cursor-pointer" @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
          </div>
          <div class="add-specific-specialism__body w-100 flex-column">
            <CategoryForm @get-data="getData" :passedCategories="categories" :showTitle="false" :isDialog="true"/>
          </div>
        </div>
    </div>
  </BaseModal>
</template>
<script>
import { defineComponent } from '@vue/runtime-core';
import { mapGetters } from 'vuex';

import CategoryForm from '@/core/components/common/forms/category/CategoryForm.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    BaseModal,
    CategoryForm,
  },

  emits: ['on-cancel', 'push-to-address-list', 'update-address-list'],

  props: ['show', 'userCertificates'],

  data() {
    return {

      saving: false,

      isFormReady: false,

      categories: []
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
  },

  watch: {
  },

  methods: {

    cancel() {
      this.$emit('on-cancel');
    },

    initialize() {
    },

    onSave() {
      this.saving = true;
    },

    getData(categories) {
      console.log(categories, 'emited categories');
      //   const subCategoryIds = [];

      //   for (const category of categories) {
      //     const { subCategories } = category;

      //     for (const subCategory of subCategories) {
      //       const { id } = subCategory;

      //       if (!subCategoryIds.includes(id)) {
      //         subCategoryIds.push(id);
      //       }
      //     }
      //   }

      //   this.setProjectCategories(categories);
      //   this.$emit('on-update-value', {
      //     type: 'categories',
      //     value: subCategoryIds
      //   });
    },
  },
});
</script>
<style lang="scss" scoped>
 .add-specific-specialism {
    min-width: 400px;
    position: relative;
    max-height: 500px;
    min-height: 500px;
    max-width: 400px;
    height: 100%;

    &__container {
      gap: 1.5rem;
      height: 100%;
    }

    &__body {
      height: 100%;
    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
    }
 }
</style>
