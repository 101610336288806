<template>
  <el-dialog
    :top="marginTop"
    :modelValue="show"
    :append-to-body="true"
    :close-on-click-modal="false"
    :show-close="false"
    :close-on-press-escape="false"
  >
    <div class="wizard-modal flex-column gap-0">
      <section class="wizard-modal__header flex-column ai-c jc-c">
        <Icon iconName="iknowa-logo-v4.svg" iconHeight="29px" iconWidth="160px"/>
        <Typography variant="h4">{{ header }}</Typography>
        <Icon
          class="wizard-modal__close-icon cursor-pointer"
          iconName="x.svg"
          iconHeight="16px"
          iconWidth="16px"
          @click="closeModal"/>
        <!-- <div
          @click="onBack"
          class="wizard-modal__back-button flex-row ai-c gap-half cursor-pointer">
          <el-icon :size="17" color="#FFF"><ArrowLeftBold/></el-icon>
          <Typography variant="p" textColor="#FFF">Back</Typography>
        </div> -->
      </section>
      <section class="wizard-modal__content">
        <slot name="content"></slot>
      </section>
    </div>
  </el-dialog>
</template>
<script>
// import { ArrowLeftBold } from '@element-plus/icons';
import { defineComponent } from '@vue/runtime-core';

import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Icon,
    Typography,
    // ArrowLeftBold
  },

  emits: ['close', 'on-back', 'show'],

  props: {
    show: Boolean,
    showBusinessTypeOnly: Boolean,
    showWorkstationFormOnly: Boolean,
    header: String
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },

    onBack() {
      this.$emit('on-back');
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.wizard-modal {
  min-width: 1000px;
  max-width: 1000px;
  min-height: 760px;
  max-height: 800px;

  &__header {
    background: #E7EBF7;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 130px;
    min-height: 130px;
    position: relative;
  }
  &__content {
    height: calc(760px - 130px);
    padding: 40px 80px 1.5rem 80px;
    // box-sizing: border-box;
  }

  &__close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__back-button {
    position: absolute;
    top: -30px;
    left: 0;
  }
}

@media screen and (max-width: 1080px) {
  .wizard-modal {
    min-width: 700px;

    &__content {
      padding: 15px;
    }
  }
}

@include media-sm-max-width() {
  .wizard-modal {
    min-width: 500px;
    max-height: unset;
  }
}

@include media-xs-max-width() {
  .wizard-modal {
    min-width: 300px;
  }
}
</style>
