<template>
    <div class="info-card default-card flex-column jc-sb relative">
      <!-- Header -->
      <slot name="custom-header" v-if="hasCustomHeader"></slot>
      <section class="flex-row jc-sb ai-c w-100" v-else>
        <div class="flex-row ai-c gap-half">
          <Icon :iconName="iconName" iconWidth="20px" iconHeight="20px" :customPath="customPath"/>
          <Typography variant="h6">{{ title }}</Typography>
        </div>
        <Icon iconName="info-unfilled.svg" iconWidth="16px" iconHeight="16px"/>
      </section>

      <!-- Content -->
      <section class="h-100 w-100 overflow-y-auto" v-if="!isEmpty && !isLoading">
        <slot name="custom-content"></slot>
      </section>

      <!-- Loading state -->
      <section class="h100" v-if="isEmpty && isLoading" v-loading="isEmpty && isLoading">
      </section>

      <!-- Empty state -->
      <section class="h-100 w-100 overflow-y-auto" v-if="isEmpty && !isLoading">
        <slot name="custom-empty-state" v-if="hasCustomEmptyState"></slot>
        <Typography variant="p" v-else>{{ emptyStateLabel }}</Typography>
      </section>

      <!-- Footer -->
      <section class="info-card__footer w-100 h-auto flex-row ai-c" v-if="!hideFooter">
        <div class="w-100 flex-row ai-c jc-fe">
          <slot name="custom-footer" v-if="hasCustomFooter"></slot>
          <ActionItemComponent
            v-else
            :label="actionLabel"
            icon="arrow-right.svg"
            iconSize="20"
            fontColor="#4B4BFF"
            fontSize="14px"
            :isReverse="true"
            iconColor="#4B4BFF"
            @call-to-action="handleEvent"/>
        </div>
      </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Icon,
    Typography,
    ActionItemComponent
  },

  emits: ['handle-event-click'],

  props: {
    iconName: String,
    customPath: String,
    title: String,
    hasCustomHeader: {
      type: Boolean,
      default: false
    },
    hasCustomFooter: {
      type: Boolean,
      default: false
    },
    hideFooter: Boolean,
    actionLabel: String,

    isEmpty: Boolean,
    hasCustomEmptyState: Boolean,
    emptyStateLabel: String,

    isLoading: Boolean
  },

  methods: {
    handleEvent() {
      this.$emit('handle-event-click');
    }
  },
});
</script>
<style lang="scss" scoped>
.el-divider {
    margin: .5rem 0;
}
.info-card {
  max-height: 370px !important;
  padding-bottom: 0rem !important;

  &__footer {
    min-height: 60px;
    border-top: 1px solid #C5D2F2;
  }
}
</style>
