<template>
  <SetupDialogSlot header="Trade Specialism" @close="onClose" :show="show">
    <template #content>
      <Specialism @next="onClose"/>
    </template>
  </SetupDialogSlot>
</template>
<script>
import { defineComponent } from 'vue';

import SetupDialogSlot from '@/core/components/slots/SetupDialogSlot.vue';
import Specialism from '@/modules/account-setup/on-boarding/Specialism.vue';

export default defineComponent({
  components: {
    Specialism,
    SetupDialogSlot
  },

  emits: ['close', 'show'],

  data() {
    return {};
  },

  computed: {
  },

  methods: {
    onComplete() {
    },

    onClose(hasSavedNewData = false) {
      this.$emit('close', hasSavedNewData);
    }
  },
});
</script>
<style lang="scss" scoped>
</style>
