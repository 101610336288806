<template>
  <el-container class="specialism flex-column jc-sb">
    <section class="specialism__container flex-column">
      <div class="specialism__header flex-column gap-half">
        <Typography variant="h3" textWeight="400">What are your trade specialisms {{ user?.firstName.charAt(0).toUpperCase() + user?.firstName.slice(1) }}?</Typography>
        <typography variant="h6" textColor="rgba(12, 15, 74, 0.5)" textWeight="500">
          Please select as many as you like.
        </typography>
      </div>
      <div class="specialism__content">
        <SpecialismList
          v-if="screenType === DEFAULT_SCREEN"
          :selectedCategories="selectedCategories"
          :availableCategories="availableCategories"
          @select-category="handleSelectCategory"/>
        <SearchSpecialism
          :selectedSubCategories="selectedSubCategories"
          :selectedCategories="selectedCategories"
          :availableCategories="availableCategories"
          @on-cascade-category-change="categoryChange"
          @remove-category="removeCategory"
          v-else/>
      </div>
      <div class="specialism__footer flex-row ai-c gap-half" v-if="screenType === DEFAULT_SCREEN">
        <Typography
          class="cursor-pointer"
          variant="p"
          textColor="rgba(12, 15, 74, 0.5)"
          textWeight="500">
          Can’t see your spcialism?
        </Typography>
        <div class="flex-row ai-c gap-half cursor-pointer" @click="setScreen(SEARCH_SKILLS_SCREEN)">
          <Typography
            class="cursor-pointer"
            variant="p"
            whiteSpace="nowrap"
            textColor="#FAA500"
            textWeight="500">
            Search from our full list of trade specialisms
          </Typography>
          <el-icon :size="14" color="#FAA500"><ArrowRightBold/></el-icon>
        </div>
      </div>
    </section>
    <section class="specialism__wrapper footer flex-column">
      <div class="flex-row ai-c gap-half cursor-pointer" v-if="screenType === SEARCH_SKILLS_SCREEN" @click="setScreen(DEFAULT_SCREEN)">
        <el-icon :size="14" color="#FAA500"><ArrowLeftBold/></el-icon>
        <Typography
          class="cursor-pointer"
          variant="p"
          whiteSpace="nowrap"
          textColor="#FAA500"
          textWeight="500">
          Back
        </Typography>
      </div>
      <Button
        :isActive="isFormActive"
        :buttonText="submittingForm ? 'saving...' : 'save'"
        @handle-click="onNext"
        :loading="submittingForm"/>
    </section>
  </el-container>
</template>
<script>
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import Button from '@/core/components/ui/Button.vue';
import Typography from '@/core/components/ui/Typography.vue';
import SearchSpecialism from '@/modules/account-setup/components/SearchSpecialism.vue';
import SpecialismList from '@/modules/account-setup/components/SpecialismList.vue';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USERS_STORE } from '@/store/modules/users';

const DEFAULT_SCREEN = 'default';
const SEARCH_SKILLS_SCREEN = 'skills-search';

export default defineComponent({
  components: {
    Typography,
    Button,
    ArrowRightBold,
    SpecialismList,
    SearchSpecialism,
    ArrowLeftBold
  },

  emits: ['next'],

  data() {
    return {
      isFormActive: false,
      selectedCategories: [],
      modifiedCategories: [],
      selectedSubCategories: [],
      submittingForm: false,
      userSkills: [],
      hasNoEllipsis: [],

      screenType: DEFAULT_SCREEN,

      DEFAULT_SCREEN,
      SEARCH_SKILLS_SCREEN
    };
  },

  computed: {
    ...mapGetters(PROJECTS_STORE, [
      'categories',
      'projectStatuses',
      'mainCategories',
      'subCategories',
      'toBePassedCategories'
    ]),

    ...mapGetters(USERS_STORE, ['user']),

    availableCategories() {
      const { categories } = this;

      if (categories.length && categories !== null && categories) {
        return this.transformCategoriesToOptions(categories);
      }

      return [];
    },
  },

  watch: {
    selectedCategories: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value.length) {
          this.isFormActive = true;
        } else {
          this.isFormActive = false;
        }
      }
    }
  },

  async created() {
    await this.getCategories();
    this.userSkills = await this.getUserSkills(this.user.id);
    this.setSelectedCategoriesRecord(this.userSkills);
  },

  methods: {
    ...mapActions(PROJECTS_STORE, [
      'getCategories',
      'setMainCategories',
      'setSubCategories',
      'setToBePassedCategories'
    ]),

    ...mapActions(USERS_STORE, ['getUserVerificationStatus', 'getUserSkills', 'saveUserSkills', 'getAccountStatuses']),

    handleSelectCategory(value) {
      if (!this.selectedCategories.includes(value)) {
        this.selectedCategories.push(value);
      } else {
        const index = this.selectedCategories.indexOf(value);
        this.selectedCategories.splice(index, 1);
      }
    },

    removeCategory(category) {
      const index = this.selectedCategories.indexOf(category.value);
      this.selectedCategories.splice(index, 1);
    },

    transformCategoriesToOptions(categories) {
      return categories.map((category) => {
        return {
          value: category.id,
          label: category.tpLabel,
          iconName: category.iconName,
          children: category.subCategories.map((sub) => {
            const modifiedLabel = sub.label.replace(/\n/g, '');
            return { value: sub.id, label: modifiedLabel };
          }),
        };
      });
    },

    setSelectedCategoriesRecord(userSkills) {
      const { selectedCategories, selectedSubCategories } = this;

      if (userSkills.length) {
        userSkills.forEach((item) => {
          const categoryId = item.subCategory.category.id;
          const subCategoryId = item.subCategory.id;
          // Check if the category ID is not already in the selectedCategories array
          if (!selectedCategories.includes(categoryId)) {
            selectedCategories.push(categoryId);
          }
          if (!selectedSubCategories.includes(subCategoryId)) {
            selectedSubCategories.push(subCategoryId);
          }
        });
      }
    },

    // parseDataUserSkills() {
    //   return {
    //     subCategoryId: this.availableCategories
    //       .filter((category) => this.selectedCategories.includes(category.value))
    //       .flatMap((category) => category.children.map((subCategory) => subCategory.value))
    //   };
    // },

    // Return only the first index on each subcategory of the selectedCategories
    parseDataUserSkills() {
      return {
        subCategoryId: this.availableCategories
          .filter((category) => this.selectedCategories.includes(category.value))
          .flatMap((category) => (category.children.length > 0 ? [category.children[0].value] : []))
      };
    },

    onNext() {
      let { userSkills } = this;
      this.submittingForm = true;

      userSkills = this.parseDataUserSkills();

      this.saveUserSkills({
        userId: this.user.id,
        skills: userSkills
      })
        .then(async () => {
          this.$notify.success({
            message: 'Skill categories successfully saved.'
          });
          this.$emit('next', true);
        })
        .catch((e) => {
          this.$notify.error({
            message: e || 'Error submitting Skill categories details.'
          });
        })
        .finally(() => {
          this.submittingForm = false;
        });
    },

    onHandleClickSee(event, index) {
      event.stopPropagation();

      const valueExistIndex = this.hasNoEllipsis.indexOf(index);

      if (valueExistIndex === -1) {
        this.hasNoEllipsis.push(index);
      } else {
        this.hasNoEllipsis.splice(valueExistIndex, 1);
      }
    },

    setScreen(screenType) {
      this.screenType = screenType;
    },

    categoryChange(newCategories) {
      // Flatten the newCategories array and remove duplicates
      const flattenedCategories = [...new Set(newCategories.flat())];

      // Add unique values to the selectedCategories array
      this.selectedCategories = [...new Set([...this.selectedCategories, ...flattenedCategories])];
    },

    cleanUp() {
      let { selectedSubCategories, modifiedCategories, selectedCategories } = this;

      // Clear the contents of the arrays
      selectedSubCategories = [];
      modifiedCategories = [];
      selectedCategories = [];

      // Update the properties in the component with reactivity
      this.selectedSubCategories = selectedSubCategories;
      this.modifiedCategories = modifiedCategories;
      this.selectedCategories = selectedCategories;
    }
  },

  beforeUnmount() {
    this.cleanUp();
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.specialism {
  height: calc(100% - 4rem);
  flex: unset;
  flex-basis: unset;

  &__container {
    height: 100%;
    overflow: hidden;
  }

  &__wrapper {
    width: 100%;
  }

  &__wrapper.grid {
    grid-template-columns: 1fr 1fr;
  }

  &__wrapper.footer {
    width: 50%;
  }

  &__content {
    height: 100%;
    overflow-y: hidden;
  }

  &__scrollbar-content {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(65px, 1fr));
    padding: 0.4rem 0.5rem;
    box-sizing: border-box;
  }

  &__tooltip-content {
    max-width: 250px;
  }
}

@include media-sm-max-width() {
  .specialism {
    &__wrapper.footer {
      width: 100%;
    }
  }
}

@include media-xs-max-width() {
  .specialism {
    &__scrollbar-content {
      gap: .7rem;
    }

    &__header {
      padding-left: .5rem;
    }

    // &__wrapper.footer {
    //   padding-left: .5rem;
    // }

    &__footer {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
      margin-left: .5rem;
    }
  }
}

@media screen and (max-width: 509px) {
  .specialism {
    &__scrollbar-content {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }
}
</style>
