<template>
  <div class="search-specialism flex-column gap-half">
    <Typography variant="h6">More Trade Specialisms</Typography>
    <CategoryCascader
      class="search-specialism__cascader"
      :categories="availableCategories"
      :mainCat="selectedCategories"
      :subCat="selectedSubCategories"
      :removeShadow="false"
      cascaderHeight="235px"
      @category-change="categoryChange"/>
    <section class="search-specialism__selected-wrapper grid">
      <SpecialismItem
        v-for="(category, index) in categoriesToDisplay"
        :isActive="true"
        :key="index"
        :category="category"
        @click="removeCategory(category)"/>
    </section>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import CategoryCascader from '@/core/components/common/forms/category/CategoryCascader.vue';
import Typography from '@/core/components/ui/Typography.vue';
import SpecialismItem from '@/modules/account-setup/components/SpecialismItem.vue';
import { PROJECTS_STORE } from '@/store/modules/projects';

export default defineComponent({
  components: {
    CategoryCascader,
    Typography,
    SpecialismItem
  },

  emits: ['on-cascade-category-change', 'remove-category'],

  props: ['selectedCategories', 'selectedSubCategories', 'availableCategories'],

  data() {
    return {
      currentCategories: [],
      clonedMapCategories: [],
      clonedCategories: [],
      dataToSubmit: {}
    };
  },

  computed: {
    ...mapGetters(PROJECTS_STORE, [
      'categories',
      'projectStatuses',
      'mainCategories',
      'subCategories',
      'toBePassedCategories'
    ]),

    categoriesToDisplay() {
      // Filter availableCategories based on selectedCategories
      return this.availableCategories.filter((category) => {
        return this.selectedCategories.includes(category.value);
      });
    },
  },

  async created() {
    await this.getCategories();
  },

  methods: {
    ...mapActions(PROJECTS_STORE, [
      'getCategories',
      'setMainCategories',
      'setSubCategories',
      'setToBePassedCategories'
    ]),

    categoryChange(newCategories) {
      this.$emit('on-cascade-category-change', newCategories);
    },

    removeCategory(category) {
      this.$emit('remove-category', category);
    }
  },
});
</script>
<style lang="scss" scoped>
.search-specialism {
    height: 100%;

    &__cascader {
      padding-left: .5rem;
      :deep(.el-input__inner) {
        background: rgba(12, 15, 74, 0.05);
      }
    }

  &__selected-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(65px, 1fr));
    padding: 0.4rem 0.5rem;
    box-sizing: border-box;
    overflow-y: auto;
  }
}
</style>
