<template>
  <div
    class="specialism-list-item flex-row ai-fs cursor-pointer"
    :class="[{'active': isActive}]">
    <div class="specialism-list-item__icon flex-column ai-c jc-c">
      <Icon
        iconHeight="15px"
        iconWidth="15px"
        :iconName="category?.iconName.concat('.svg')"
        customPath="category-icons"/>
    </div>
    <div class="specialism-list-item__text-wrapper flex-column">
      <Typography variant="h6">{{ category?.label }}</Typography>
      <div class="specialism-list-item__ellipsis-container flex-row ai-fe gap-0">
        <Typography
        class="specialism-list-item__label-text text-ellipsis"
        whiteSpace="nowrap"
        variant="p"
        textWeight="500"
        lineHeight="18px"
        textColor="rgba(12, 15, 74, 0.50)">
        {{ getLabel(category?.children, 6) }}
        </Typography>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Typography,
    Icon
  },

  props: ['category', 'isActive'],

  methods: {
    getLabel(subCategories, count = null) {
      let labels = [];
      let joinedLabels = '';

      if (count !== null) {
        labels = subCategories.slice(0, count).map((item) => {
          return item.label;
        });
      } else {
        labels = subCategories.map((item) => {
          return item.label;
        });
      }

      joinedLabels = labels.join(', ');

      if (count !== null && subCategories.length > 6) {
        return joinedLabels.concat(' etc.');
      }

      return joinedLabels;
    },
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.specialism-list-item {
    min-height: 40px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(12, 15, 74, 0.20);
    padding: .5rem 1.3rem;
    box-sizing: border-box;
    overflow: hidden;
    max-height: 65px;
    min-height: 65px;

    &__icon {
        background: #0C0F4A;
        border-radius: 100%;
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        margin-top: .3rem;
    }

    &__text-wrapper {
        gap: .1rem;
        overflow: hidden;
    }

    &__label-text {
        max-width: 350px;
    }
}

.specialism-list-item.active {
  border: 2px solid #4F55F0;
}

.specialism-list-item.unset-max-height {
  max-height: unset;
}

@include media-xs-max-width() {
  .specialism-list-item {
    gap: 0.5rem;
    padding: 0.5rem 1rem;
  }
}

@media screen and (max-width: 509px) {
  .specialism-list {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
</style>
