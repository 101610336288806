<template>
  <div class="passport-specialism-page default-layout-desktop-padding">
    <div class="passport-specialism-page__main-container flex-column h-100">
      <WelcomeBanner
        header="Your Iknowa Trades Specialisms"
        buttonLabel="add new trade specialism"
        @handle-event="setSpecialismModal(true)"/>
      <div class="flex-row gap-half">
        <Icon
          iconName="specialisms-dark.svg"
          customPath="icons/trade-passport-icons"
          iconWidth="20px" iconHeight="20px"/>
        <Typography variant="h6">Your Trade Specialisms</Typography>
      </div>
      <div class="passport-specialism-page__content col-2 w-100 grid">
        <SpecialismItemCard
          v-for="(category, index) in categoriesToDisplay"
          :key="index"
          :category="category"
          @remove-main-category="removeMainCategory"
          @remove-subCategory="removeSubCategory"/>
      </div>
    </div>

    <!-- MODAL -->
    <SpecialismModal :show="specialismModal" @close="onCloseModal"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import SpecialismModal from '@/core/components/common/modals/new-designs/SpecialismModal.vue';
import WelcomeBanner from '@/core/components/layouts/WelcomeBanner.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import SpecialismItemCard from '@/modules/trades-passport-children/components/card-item/SpecialismItemCard.vue';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  name: 'TradesPassportOverview',

  components: {
    WelcomeBanner,
    Typography,
    Icon,
    SpecialismItemCard,
    SpecialismModal
  },

  data() {
    return {
      specialismModal: false,
      userSkills: [],
      selectedCategories: [],
      selectedSubCategories: [],
      isLoading: false,
      categoriesToDisplay: [],
      submittingForm: false,
    };
  },

  async created() {
    await this.getCategories();
    await this.onInitializeUserSkills();
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
    ...mapGetters(PROJECTS_STORE, [
      'categories',
    ]),
  },

  methods: {
    ...mapActions(PROJECTS_STORE, [
      'getCategories',
    ]),

    ...mapActions(USERS_STORE, [
      // skills
      'saveUserSkills',
      'getUserSkills',
    ]),

    async onInitializeUserSkills() {
      this.isLoading = true;
      this.resetDetails();
      this.userSkills = await this.getUserSkills(this.user?.id);

      this.setSelectedCategoriesRecord(this.userSkills);
      this.getCategoriesToDisplay();
      this.isLoading = false;
    },

    resetDetails() {
      this.userSkills = [];
      this.categoriesToDisplay = [];
      this.selectedSubCategories = [];
      this.selectedCategories = [];
    },

    setSpecialismModal(state) {
      this.specialismModal = state;
    },

    onCloseModal(hasSavedNewData = false) {
      if (hasSavedNewData) {
        this.onInitializeUserSkills();
      }

      this.setSpecialismModal(false);
    },

    setSelectedCategoriesRecord(userSkills) {
      const { selectedCategories, selectedSubCategories } = this;
      userSkills.forEach((item) => {
        const categoryId = item.subCategory.category.id;
        const subCategoryId = item.subCategory.id;
        // Check if the category ID is not already in the selectedCategories array
        if (!selectedCategories.includes(categoryId)) {
          selectedCategories.push(categoryId);
        }
        if (!selectedSubCategories.includes(subCategoryId)) {
          selectedSubCategories.push(subCategoryId);
        }
      });
    },

    getCategoriesToDisplay() {
      const categoriesToDisplay = this.userSkills.reduce((result, skill) => {
        const categoryId = skill.subCategory.category.id;
        const categoryName = skill.subCategory.category.tpLabel;
        const categoryIconName = skill.subCategory.category.iconName;
        const subCategoryId = skill.subCategory.id;
        const subCategoryLabel = skill.subCategory.label;

        // Find the category in result array
        let category = result.find((item) => item.value === categoryId);

        // If the category doesn't exist, create a new one
        if (!category) {
          category = {
            value: categoryId,
            label: categoryName,
            iconName: categoryIconName,
            children: [],
          };
          result.push(category);
        }

        // Add subCategoryId to the category's children
        category.children.push({
          value: subCategoryId,
          label: subCategoryLabel
        });

        return result;
      }, []);

      this.categoriesToDisplay = categoriesToDisplay;
    },

    // ACTIONS ( REMOVE , ADD ) //

    parseUserSkills() {
      return {
        subCategoryId: this.userSkills.map((skill) => skill.subCategory.id)
      };
    },

    removeSubCategory(subCategoryIdToRemove) {
      this.userSkills = this.userSkills.filter((item) => item.subCategory.id !== subCategoryIdToRemove);
      const newParseUserSkills = this.parseUserSkills();
      this.onSave(newParseUserSkills);
    },

    removeMainCategory(mainCategoryIdToRemove) {
      this.userSkills = this.userSkills.filter((item) => item.subCategory.category.id !== mainCategoryIdToRemove);
      const newParseUserSkills = this.parseUserSkills();
      this.onSave(newParseUserSkills);
    },

    onSave(userSkills) {
      // let { userSkills } = this;
      this.submittingForm = true;

      this.saveUserSkills({
        userId: this.user.id,
        skills: userSkills
      })
        .then(async () => {
          this.$notify.success({
            message: 'Skill sub-category successfully removed.'
          });
          this.getCategoriesToDisplay();
        })
        .catch((e) => {
          this.$notify.error({
            message: e || 'Error removing Skill sub-category.'
          });
        })
        .finally(() => {
          this.submittingForm = false;
        });
    }
  },
});
</script>

<style lang="scss" scoped>
.passport-specialism-page {
  box-sizing: border-box;

  &__content {
  }

  &__content.col-2 {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
